<template>
  <o-table
    :items="spotTrackers"
    :loading="loading"
    defaultSort="number"
    :draggable="false"
    paginated
    :per-page="100"
  >
    <b-table-column v-slot="{ index }" cell-class="has-text-grey">
      {{ index + 1 }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="number" label="Numéro" sortable>
      {{ row.number }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="serialNumber" label="ESN" sortable>
      {{ row.serialNumber }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="authorizationCode" label="Auth" sortable>
      {{ row.authorizationCode }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="expiredAt" label="Date d'expiration" sortable>
      <span :class="isPast(row.expiredAt) ? 'has-text-danger has-text-weight-medium' : ''">
        {{ formatDate(row.expiredAt) }}
      </span>
    </b-table-column>
    <b-table-column v-slot="{ row }" field="availability" label="Status" sortable>
      <b-tag :type="tags[row.availability].type">
        {{ tags[row.availability].label }}
      </b-tag>
    </b-table-column>
    <b-table-column v-slot="{ row }" field="owner" label="Propriétaire" sortable>
      {{ capitalize(row.owner) }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="firmwareVersion" label="Version" sortable>
      {{ row.firmwareVersion || '–' }}
    </b-table-column>
    <b-table-column v-slot="{ row }" label="">
      <div class="buttons are-small is-right">
        <b-button
          type="is-primary"
          tag="router-link"
          :to="{ name: 'spotTracker.edit', params: { id: row.id } }"
        >
          <b-icon icon="edit" />
        </b-button>
      </div>
    </b-table-column>
  </o-table>
</template>

<script>
import { format, isPast } from 'date-fns'
import { capitalize } from '@helpers/capitalize'
import { TAGS } from '@constants/tracker/tag'
import OTable from '@components/Table.vue'

export default {
  name: 'ListSpotTrackersTable',

  components: {
    OTable,
  },

  props: {
    spotTrackers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },

  created() {
    this.tags = TAGS
  },

  methods: {
    formatDate(date) {
      return !date ? 'Aucune date' : format(date, 'dd/MM/yyyy')
    },

    capitalize(value) {
      return capitalize(value)
    },

    isPast(date) {
      return isPast(date)
    },
  },
}
</script>
