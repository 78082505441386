<template>
  <div class="level is-flex-direction-column">
    <div class="level-right ml-auto">
      <p class="level-item">
        <b-input
          :value="$route.query.keyword"
          type="text"
          placeholder="Numéro, ESN, Version..."
          :icon-right="$route.query.keyword ? 'close-circle' : ''"
          icon-right-clickable
          @keyup.native.enter="fetch({ ...$route.query, keyword: $event.target.value })"
          @icon-right-click="fetch({ ...$route.query, keyword: undefined })"
        />
      </p>
      <p class="level-item">
        <b-select
          :value="$route.query.availability"
          placeholder="Choisir un status"
          icon="history"
          @input="fetch({ ...$route.query, availability: $event })"
        >
          <option :value="null">Choisir un status</option>
          <option
            v-for="availability in Object.keys(availabilities)"
            :key="availability"
            :value="availability"
          >
            {{ availabilities[availability] }}
          </option>
        </b-select>
      </p>
      <p class="level-item">
        <b-select
          :value="$route.query.expired"
          placeholder="Choisir par abonnement"
          icon="history"
          @input="fetch({ ...$route.query, expired: $event })"
        >
          <option :value="null">Choisir par abonnement</option>
          <option :value="true">Expiré</option>
          <option :value="false">Abonné</option>
        </b-select>
      </p>
      <p class="level-item">
        <b-select
          :value="$route.query.owner"
          placeholder="Choisir un propriétaire"
          icon="user"
          @input="fetch({ ...$route.query, owner: $event })"
        >
          <option :value="null">Choisir un propriétaire</option>
          <option v-for="owner in owners" :key="owner" :value="owner">
            {{ capitalize(owner) }}
          </option>
        </b-select>
      </p>
    </div>
    <div class="level-right ml-auto">
      <p class="level-item">
        <b-button v-show="showClearFilterButton" class="is-small" type="is-text" @click="fetch({})">
          Supprimer tous les filtres
        </b-button>
      </p>
    </div>
  </div>
</template>

<script>
import { OWNERS } from '@constants/tracker/owner.js'
import { AVAILABILITIES } from '@constants/tracker/availability.js'
import { capitalize } from '@helpers/capitalize.js'

export default {
  name: 'ListSpotTrackersFilter',

  created() {
    this.owners = OWNERS
    this.availabilities = AVAILABILITIES
  },

  computed: {
    showClearFilterButton() {
      return Object.values(this.$route.query).filter((v) => v).length > 0
    },
  },

  methods: {
    async fetch(query) {
      this.$router.push({ query })
    },

    capitalize(value) {
      return capitalize(value)
    },
  },
}
</script>
