<template>
  <page-layout :title="title">
    <template v-slot:breadcrumb>
      <li><router-link :to="{ name: 'home' }">Dashboard</router-link></li>
      <li>
        <router-link :to="{ name: 'spotTracker.list' }"> Balises SPOT </router-link>
      </li>
      <li class="is-active">
        <a href="#">{{ title }}</a>
      </li>
    </template>
    <div class="columns">
      <div class="column is-full-mobile is-6 ml-auto">
        <spot-tracker-search />
      </div>
    </div>
    <div v-if="spotTracker">
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <div class="tile is-child card">
            <div class="card-header">
              <h2 class="card-header-title">Informations</h2>
              <div class="my-auto mx-3">
                <b-tag :type="tags[spotTracker.availability].type">
                  {{ tags[spotTracker.availability].label }}
                </b-tag>
              </div>
            </div>
            <div class="card-content">
              <table class="table is-striped is-fullwidth">
                <tr>
                  <th>Numéro</th>
                  <td>{{ spotTracker.number }}</td>
                </tr>
                <tr>
                  <th>ESN</th>
                  <td>{{ spotTracker.serialNumber }}</td>
                </tr>
                <tr>
                  <th>Auth</th>
                  <td>{{ spotTracker.authorizationCode }}</td>
                </tr>
                <tr>
                  <th>Date d'achat</th>
                  <td>{{ formatDate(spotTracker.purchasedAt) }}</td>
                </tr>
                <tr>
                  <th>Date d'expiration</th>

                  <td
                    :class="
                      isPast(spotTracker.expiredAt) ? 'has-text-danger has-text-weight-medium' : ''
                    "
                  >
                    {{ formatDate(spotTracker.expiredAt) }}
                  </td>
                </tr>
                <tr>
                  <th>Locations depuis l'achat</th>
                  <td>{{ lives.length }}</td>
                </tr>
                <tr>
                  <th>Locations depuis l'abonnement annuel</th>
                  <td>{{ totalLivesFromExpiredAt }}</td>
                </tr>
                <tr>
                  <th>Version du firmware</th>
                  <td>{{ spotTracker.firmwareVersion || '–' }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child card">
            <spot-tracker-map :id="id" class="card" />
          </div>
        </div>
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <div class="tile is-child card">
            <div class="card-header">
              <h2 class="card-header-title">Changement d'état</h2>
            </div>
            <div class="card-content">
              <spot-tracker-edit-form :spotTracker.sync="spotTracker" />
            </div>
          </div>
        </div>
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <div class="tile is-child card">
            <div class="card-header">
              <h2 class="card-header-title">Lives</h2>
            </div>
            <div class="card-content">
              <spot-tracker-live-table :lives="formattedLives" :loading="loading" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import { format, isPast, isWithinInterval, subYears } from 'date-fns'
import PageLayout from '@components/PageLayout.vue'
import SpotTrackerMap from './SpotTrackerMap.vue'
import SpotTrackerSearch from './SpotTrackerSearch.vue'
import SpotTrackerEditForm from './SpotTrackerEditForm.vue'
import SpotTrackerLiveTable from './SpotTrackerLiveTable.vue'
import { TAGS } from '@constants/tracker/tag'

export default {
  name: 'EditSpotTrackerPage',

  components: {
    SpotTrackerMap,
    SpotTrackerSearch,
    SpotTrackerLiveTable,
    [PageLayout.name]: PageLayout,
    [SpotTrackerEditForm.name]: SpotTrackerEditForm,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      spotTracker: null,
      lives: [],
      liveVehicles: [],
    }
  },

  created() {
    this.tags = TAGS
  },

  watch: {
    id: {
      handler: 'load',
      immediate: true,
    },
  },

  computed: {
    title() {
      return this.spotTracker ? `SPOT ${this.spotTracker.number}` : '...'
    },

    totalLivesFromExpiredAt() {
      return this.lives.filter(
        (live) =>
          this.spotTracker.expiredAt &&
          (isWithinInterval(live.startedAt, {
            start: subYears(this.spotTracker.expiredAt, 1),
            end: this.spotTracker.expiredAt,
          }) ||
            isWithinInterval(live.endedAt, {
              start: subYears(this.spotTracker.expiredAt, 1),
              end: this.spotTracker.expiredAt,
            }) ||
            isWithinInterval(this.spotTracker.expiredAt, {
              start: live.startedAt,
              end: live.endedAt,
            })),
      ).length
    },

    formattedLives() {
      return this.lives.map((live) => ({
        ...live,
        vehicleId: this.liveVehicles[live.id]?.id,
        vehicleNumber: this.liveVehicles[live.id]?.number,
      }))
    },
  },

  methods: {
    async load() {
      this.loading = true

      this.spotTracker = await this.$services.spotTrackerService.getById(this.id)
      this.lives = await this.$services.liveService.getAllBySpotTrackerId(this.id)
      this.liveVehicles = await this.fetchLiveVehicles()

      this.loading = false
    },

    async fetchLiveVehicles() {
      return (await this.$services.liveVehicleService.getAllBySpotTrackerId(this.id)).reduce(
        (vehicles, vehicle) => ({
          ...vehicles,
          [vehicle.liveId]: { ...vehicle },
        }),
        {},
      )
    },

    formatDate(date) {
      return !date ? 'Aucune date' : format(date, 'dd/MM/yyyy')
    },

    isPast(date) {
      return isPast(date)
    },
  },
}
</script>
